body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height:100vh;
  background:url('bgV2.jpg') rgba(194, 194, 194, 0.815);
  
  background-size:cover;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
  background-size:cover;
}

.fullHeight {
  min-height: 100vh;
}

.btnLndng{
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.btnStartOver {
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background: #05419b!important;
  border: none!important;
  font-size: 1.3rem!important;
  padding: 20px!important;
  box-sizing: border-box;
}

.btnOTP{
  height: 100%;
  margin-left: 10px;
  margin-bottom: 0px;
  /* margin-top: ; */
}

.btnChoice{
  width: 206px;
  padding: 13px;
  background-color: #fff;
  border-radius: 70px;
  border: 1px solid #61e1cf;
  cursor: pointer;
  color: #797979;
  box-shadow: 0 0 8px 0 #ccc;
  height: 66px;
  margin: 20px auto;
  font-size: 13px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: strong;
  color: black;
}

.formInput{
  height: 37px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.italic{
  font-size: 15px;
  font-style: italic;
}

.inputMap{
  width: 87%;
  height: 35px;
}

.btnMap{
  margin-left: 10px;
  margin-bottom: 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.red{
  background-color: red;
}

.primeCandidate{
margin-top: 0%;
margin-bottom: 5%;
}

/* 
.cntnr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  margin-top: 7%;
  width: 33%!important;
  height: 250px!important;

  background: rgba(253, 253, 253, 0.980);
}
*/

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}



.top{
  margin-top: 3.3%;
}


.contentBox{
  border-radius: 7px;
  background: hsla(0, 0%, 100%, 0.877);

}

.widthControl{
  width: 30%;
}

.color-1 {
  color: orange;
}

.modal-background {
  background: rgba(253, 253, 253, 0.980);
  position: absolute;
  left: 20%;
  right: 20%;
  top: 10%;

  padding: 0px;
  margin: 0px;
}

.modal {
  background: white;
  margin: 0px;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  bottom: 20px;
  right: 20px;
  padding: 20px;
  display: inline-block;
  max-width: 100%; 
  max-height: 100%;
  overflow: auto; 
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}
.progress {
  width: 80%;
  height: 50px;
  background: rgb(102, 138, 172);
}

.progress div {
  height: 100%;
  background: rgba(66, 88, 200, 0.88);
}

.inBetween{
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.homeowner {
  padding: 10%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%!important; /* Adjust the width as needed */
  margin: 0 auto; /* Center horizontally */
}

.modal-reset {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  padding: 20px;
  text-align: center;
  background: rgba(255, 255, 255, 0.928);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
}

.saving {
  padding: 10%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%!important; /* Adjust the width as needed */
  margin: 0 auto; /* Center horizontally */
  background: white;
  margin: 0px;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  bottom: 20px;
  right: 20px;
  padding: 20px;
  display: inline-block;
  max-width: 100%; 
  max-height: 100%;
  overflow: auto; 
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.congrats{
  color: #0049b6;
}

.questioner{
  padding-top: 2.22%;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.qButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 175px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  transition: background 0.3s ease-in-out;
}

.fixer{
  margin-top: 10px;
}

.qButton::after {
  content: "";
  display: block;
  padding-bottom: 100%; /* Maintain aspect ratio */
}

.qButton:hover {
  background-color: rgba(1, 3, 105, 0.301);
}

.qButton.flatRoof {
  background-image: url('./images/flatRoof.png');
}

.qButton.slightPitch {
  background-image: url('./images/slightPitch.png');
}

.qButton.mediumPitch {
  background-image: url('./images/mediumPitch.png');
}

.qButton.steepPitch {
  background-image: url('./images/steepPitch.png');
}

.paddingD{
  margin-top: 10px;
}

.paddingB{
  margin-top: 8px!important;
}

.paddingC{
  margin-top: 15px!important;
}

.bwidth{
  width: 111px!important ;
  margin-right: 10px;
}

.formContact{ 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  background: hsla(0, 0%, 100%, 0.877);
  margin-top: 1%;
}

.labelContact{
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.inputContact{
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.containerPhone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 00px!important;

  background-color:  rgba(241, 241, 241, 0.945);
}


.phoneForm {
  display: flex;
  flex-direction: column;
  align-items: center;
 /*  background-color: #F5F5F5;
  border-radius: 8px;  */
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
}

.phoneLabel {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.inputLabel {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 16px;
}

.inputLabel:focus {
  outline: none;
  box-shadow: 0px 0px 2px 2px #a7d6f0;
}


.pPhone {
  margin-top: 20px;
  font-size: 17px;
  text-align: center;
  color: #0c203f;

}

.phoneState{
  padding: 0px 40px;
  text-align: center;
  font-size: 17px;
  font-style: italic;
}

.Awesome{
  font-size: 35px;
  color: #0049b6;
}

.stateNumber{
  color: #0049b6;
}

.b50{
  background-image: url('./images/a50v2.gif');
}

.b100{
  background-image: url('./images/a100v2.gif');
}

.b150{
  background-image: url('./images/a150v2.gif');
}

.b200{
  background-image: url('./images/a200v2.gif');
}

.fullSun{
  background-image: url('./images/fullSunV2.gif');
}

.halfShade{
  background-image: url('./images/halfShadeV2.gif');
}

.fullShade{
  background-image: url('./images/fullShadeV2.gif');
}

.notSure{
  background-image: url('./images/notSureV2.gif');
}

.meter1{
  background-image: url('./images/meter1v2.gif');
}

.meter2{
  background-image: url('./images/meter2v2.gif');
}

.meter3{
  background-image: url('./images/meter3v2.gif');
}

.meter4{
  background-image: url('./images/meter4v2.gif');
}

.map-container {
  margin-top: 20px; /* Adjust the margin as needed */
}

.mapContainer{
  padding-top: 50px;
  margin-top: 1%;

  border-radius: 7px;
  background: hsla(0, 0%, 100%, 0.877);
}

.phoneContainer{
  padding-top: 25px;
  padding-bottom: 50px;

  max-width: 720px!important;
  border-radius: 7px;
}

.containerPhone{
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 7%;
  border-radius: 7%;
}

.nShare{
  color: #2553a880;
}

.goodNews{
  color: rgb(38, 59, 119);
  margin-bottom: 3px!important;
}

.contactLetters{
  color: rgb(38, 59, 119);
}

.callNow{
  background:  rgba(253, 253, 253, 0.980);
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 16%;
}

.containerLanding{
  padding-top: 50px;
  padding-bottom: 20px;
  margin-top: 7%;

}

.paddingC{
  margin-top:140px;
  margin-bottom: 30px;
  color: #0049b6;
}

.colorFont{
  color: rgb(38, 59, 119);
}

.otpContainer{
  background: hsla(0, 0%, 100%, 0.877);
  border-radius: 7%;
  padding-top: 50px;
  margin-top: 4%;
  padding: 33px;
  padding-left: 20px!important;
  padding-right: 20px!important;
  
  max-width: 400px!important;
}

.sendAgn{
  margin-top: 20px;
}

.btnSnd{
  margin-bottom: 20px;
  margin-left: 7px;
}

.divider {
  border: none;
  height: 5px;
  width: 50%;
  max-width: 200px;
  background-color: blue;
}

.landingUpdate{
  color: rgb(38, 59, 119);
  margin-bottom: 30px;
}


.otpInput{
  height: 40px;
}

.otpButton{
margin-top: 20px;
}

.formContainer{
  margin-bottom: 0px!important;
  padding-bottom: 0px!important;
  padding: 0px!important;
}

.formLinks{
  padding-top: 5px;
  margin-bottom: 0px!important;
  padding-bottom: 0px!important;
}
.buttosRight{
  margin-top: 0px!important;
  margin-left: 50px;
}

.codeTxt{
  margin-bottom: 8px;
  margin-left: 50px;
}
.disclaimer{
  margin-top: 15px;
  font-size: 13px;
}

@media (min-width: 992px) {
  .bubble {
    width: 70%!important;
  }

  .containerLanding{
    width: 25%!important;
  }

  .cntnr {
    margin-top: 1%;
    width: 23%!important;

  }

  .landingRW {
    margin-top: 3%!important;
    width: 23%!important;
  }

  .containerPhone{
    width: 90%;
   }
}

@media (max-width: 767px) {
  .containerLanding{
    width: 88%!important;
  }

  /* .homeowner{
    padding-left: ;
  } */

  .cntnr {
    margin-top: 10%;
  }

  .inputMap{
    width: 70%;
  }

  .modal-background{
    left: 10%;
    right: 10%;
  }

  .phoneContainer{
    max-width: 380px!important;
  }

  .btnLndng{
    margin-bottom: 10px!important;
    margin-top: 10px!important;
  }
 

}

#live-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100vw;
  max-width: 100vw;
}

@media(min-width: 450px) {
  .ml-10-desktop {
    margin-left: 10px;
  }
  .mr-10-desktop {
    margin-right: 10px;
  }
}

@media (min-width: 450px) and (max-width: 768px) {
  .btnLndng {
    margin-bottom: 2px;
  }
}

@media (min-width: 766px) and (max-width: 1200px) {
  /* Custom styles for iPad screens */

.qButton{
  width: 90px;
  height: 90px;
}

.inputMap{
  width: 80%;
}

.buttonContainer {
  font-size: 10px!important;
}

}

@media (min-width: 770px) and (max-width: 1199px) {
 
  .inputMap{
    width: 70%;
  }

  .textCentered{
    text-align:center;
    width: 90px;
  }

}


.chatContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
 /* background-color: #f1f1f1; */
  border-radius: 8px;
}

.chatText {
  font-size: 18px;
  margin-bottom: 20px;
}

#live-chat {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  width: 20px!important;
}

#live-chat-script-container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: calc(50% + 50px); /* Adjust the value (50px) as needed to create spacing below #live-chat */

  width: 400px!important;
}




@media screen and (min-width: 1024px) {

  .pPhone{
    padding-left: 19%;
    padding-right: 19%;
  }
  .siqembed {
  /* width: 1000px!important; */
    min-width: 1000px;
  }

.siqanim{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.marginDown{
  margin-bottom: 50px!important;
}

}

.hover-pointer:hover {
  cursor: pointer;
}

.siqembed {
  right: 0!important;
  left: 0!important;
  top: 0!important;
  bottom: 0!important;
  margin: 0 auto!important;
}

.align-start {
  align-items: flex-start;
}

.center {
  text-align: center;
}

.flex-button-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}



@media (min-width: 1200px) and (max-width: 1500px) {
 
 .qButton{
  width: 125px;
  height: 125px;
  
 }

}

.svg-icon {
  width: 30px;
}

.btn-clear {
  background: none;
  border: none;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.relative {
  position: relative!important;
}

.positionBottom {
  position: absolute!important;
  bottom: 0;
  left: 0;
  right: 0;
}

.mb10 {
  margin-bottom: 100px!important;
}

.btnText {
  background: none!important;
  border: none!important;
  color:#0d6efd;
  margin: 0 auto;
  text-align: center;
}

.btnText:hover {
  cursor: pointer;
}

.bubble {
  /* width: 70%!important; */
  margin: 0 auto;
  text-align: justify;
  background: rgba(255, 255, 255, 0.855);
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}
